@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap');

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

body {
  font: 400 14px Roboto, sans-serif;
  background: #f8f8f8;
  -webkit-font-smoothing: antialiased;
  /*background-image: url('https://clientefielsp.s3-sa-east-1.amazonaws.com/site/patterns/patternRestaurante');*/
}

.global {
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
}

.global .container {
  flex: 1;
}

.heartbeat {
  -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
  animation: heartbeat 1.5s ease-in-out infinite both
}

@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out
  }

  10% {
    -webkit-transform: scale(.91);
    transform: scale(.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in
  }

  17% {
    -webkit-transform: scale(.98);
    transform: scale(.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out
  }

  33% {
    -webkit-transform: scale(.87);
    transform: scale(.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in
  }

  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out
  }
}

@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out
  }

  10% {
    -webkit-transform: scale(.91);
    transform: scale(.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in
  }

  17% {
    -webkit-transform: scale(.98);
    transform: scale(.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out
  }

  33% {
    -webkit-transform: scale(.87);
    transform: scale(.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in
  }

  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out
  }
}



#groupFormaDePagamento .MuiListItem-button {
  padding: 0;
}

.element {
  /* height: 250px; */
  /* width: 250px; */
  position: relative;
  top: -3em;
  margin: 0 auto;
  /* background-color: red; */
  animation-name: stretch;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-delay: 0;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-fill-mode: none;
  animation-play-state: running;
}

@keyframes stretch {
  0% {
    transform: scale(.3);
    /* background-color: red; */
    border-radius: 100%;
  }

  50% {
    /* background-color: orange; */
  }

  100% {
    transform: scale(1.5);
    /* background-color: yellow; */
  }
}


.spanFormasDePagamento .MuiFormControlLabel-root {
  width: calc(100% - 50px) !important;
}

.Loading {
  text-align: center;
}

.MuiDialogTitle-root {
  padding: 8px 16px !important;
}

.MuiDialogTitle-root h2 {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.spanFormasDePagamento .MuiFormControlLabel-root {
  margin: 0 !important;
}

.setaVoltarModal {
  color: black !important;
  margin-right: 6px !important;
  transform: rotate(180deg);
}

button:focus {
  outline-style: none !important;
}

#versao-atual {
  z-index: 99999;
  color: #000000a3;
  POSITION: ABSOLUTE;
  top: 2px;
  left: 5px;
  font-size: 60%;
  /* opacity: 0.3; */
  font-weight: 500;
}

.imgModal {
  background: white;
  text-align: center;
  width: 40px;
  position: fixed;
  margin-top: -25px;
  justify-content: center;
  align-items: center;
  right: calc(50% - 20px);
  border-radius: 100%;
}

.opacity05 {
  opacity: 0.5;
}

.pretoEBranco {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
}

.divImageCentroErro {
  width: 100vw;
  height: 100vh;
  background: white;
  text-align: center;
}

.divImageCentro {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  display: table-cell;
  vertical-align: middle;
  background: white;
  text-align: center;
}

.divImageCentro img {
  opacity: 0.3;
}



.divImageCentroCardapio {
  width: 100vw;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.divImageCentroCardapio img {
  width: 100%;
  max-width: 350px;
  opacity: 0.3;
}

.pedido-rating .MuiRating-iconEmpty {
  mix-blend-mode: exclusion;
  color: #777;
  opacity: 0.5;
}

.bold {
  font-weight: bold;
}

.container-opcoes[data-selecionado="true"],
.container-opcoes:has(input:checked) {
  background-color: #00000022;
}

.erro-sem-dados {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  padding: 2em;
  position: absolute;
}

.erro-sem-dados img {
  width: min(350px, 80vw);
  margin-bottom: 1rem;
}

.erro-sem-dados span {
  font-size: min(1.5rem, 5vw);
}


mapbox-address-minimap {
  width: 100%;
  aspect-ratio: 16 / 9;
}

.pac-container { 
  /*
      Container para buscas na API da Google,
      se não tiver z-index alto fica abaixo dos outros Dialogs
  */
  z-index: 10000!important;
}

.pac-container::after {
  display: none;
}